export const msalConfig = {
    auth: {
      clientId: "",
      authority: "https://login.microsoftonline.com/c567fe94-f430-4773-9330-9dc2f3334913",
      redirectUri: "https://nice-ground-05a3fa203.5.azurestaticapps.net"
      // For Local development - uncomment the line below and comment the line above
      // redirectUri: "http://localhost:3000"
    },
    cache: {
      // This configures where the cache will be stored
      cacheLocation: "sessionStorage", 
      
      // Set this to "true" if having issues on IE11 or Edge
      storeAuthStateInCookie: false, 
    }
};
  
// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"]
};

// LOCAL BACKEND
// export const backend = {
//   url: "http://localhost:7071/api/",
//   ping: "http://localhost:7071/api/ping?details=1",

//   // function POST payrequest ping
//   url_post_payrequest: 'https://localhost:7071/api/v2/payrequest/mbway',
//   ping_post_payrequest : 'https://localhost:7071/api/v2/payrequest/mbway/ping?details=1',

//   // function GET payrequest ping
//   url_get_payrequest: 'https://localhost:7071/api/v2/payrequest/mbway',
//   ping_get_payrequest : 'https://localhost:7071/api/v2/payrequest/mbway/ping?details=1',

// };

// DEV or PROD BACKEND
export const backend = {
  // function ping
  url: 'https://real-estate-unit-rentals-dev.azurewebsites.net/api/',
  ping : 'https://real-estate-unit-rentals-dev.azurewebsites.net/api/ping?details=1',

  // function POST payrequest ping
  url_post_payrequest: 'https://real-estate-unit-rentals-dev.azurewebsites.net/api/v2/payrequest/mbway',
  ping_post_payrequest : 'https://real-estate-unit-rentals-dev.azurewebsites.net/api/v2/payrequest/mbway?ping=1',

  // function GET payrequest ping
  url_get_payrequest: 'https://real-estate-unit-rentals-dev.azurewebsites.net/api/v2/payrequest/mbway',
  ping_get_payrequest : 'https://real-estate-unit-rentals-dev.azurewebsites.net/api/v2/payrequest/mbway?ping=1',
}

export const backend_services = {
  // function ping
  url: 'https:///api/',
  ping : 'https:///api/ping',

  // function ... ping
  // ON BACKEND - TODO - Add ping message to queue as well
  url_paycallback: 'https:///api/TODO',
  ping_paycallback : 'https:///api/TODO',
}



export const app_configuration_data = {
  url: "https://realestatestoreaccdev.blob.core.windows.net/public-configuration/location_data.json",
};

  
  // Not used at the moment
  //export const apiRequestFuncTemplateNode = {
  // url: 'https://real-estate-unit-rentals-dev.azurewebsites.net/api/funcTemplateNode',
  //  scopes: ['api:///user_impersonation']    
  //}